<template>
  <main class="main checkout">
    <div class="page-content pt-7 pb-10 mb-10">
      <div class="step-by pr-4 pl-4">
        <h3 class="title title-simple title-step">
          <a href="cart.html">1. Shopping Cart</a>
        </h3>
        <h3 class="title title-simple title-step active">
          <a href="checkout.html">2. Checkout</a>
        </h3>
        <h3 class="title title-simple title-step">
          <a href="order.html">3. Order Complete</a>
        </h3>
      </div>
      <div class="container mt-7">
        <div
          v-if="isLoggedIn"
          class="alert alert-success alert-simple alert-inline mb-4"
        >
          Logged In
          <button type="button" class="btn btn-link btn-close">
            <i class="d-icon-times"></i>
          </button>
        </div>
        <div class="card accordion" v-if="!isLoggedIn">
          <div
            class="alert alert-light alert-primary alert-icon mb-4 card-header"
          >
            <i class="fas fa-exclamation-circle"></i>
            <span class="text-body">Returning customer? </span>
            <a href="#alert-body1" class="text-primary collapse"
              >Click here to login</a
            >
          </div>
          <div class="alert-body collapsed" id="alert-body1">
            <p>
              If you have shopped with us before, please enter your details
              below. If you are a new customer, please proceed to the Billing
              section.
            </p>
            <div class="row cols-md-2">
              <form class="mb-4 mb-md-0">
                <label for="username">Username Or Email *</label>
                <input
                  type="text"
                  class="input-text form-control mb-0"
                  name="username"
                  id="username"
                  autocomplete="username"
                  :class="{
                    empty:
                      findFieldinFeedback('email') &&
                      (loginForm.email === '' ||
                        !loginForm.email.includes('@') ||
                        !loginForm.email.includes('.')),
                  }"
                  v-model.trim="loginForm.email"
                />
                <p class="mt-1 error-text" v-if="errors.email">
                  {{ errors.email[0] }}
                </p>
              </form>
              <form class="mb-4 mb-md-0">
                <label for="password">Password *</label>
                <input
                  class="input-text form-control mb-0"
                  type="password"
                  name="password"
                  id="password"
                  autocomplete="current-password"
                  :class="{
                    empty:
                      findFieldinFeedback('password') &&
                      loginForm.password === '',
                  }"
                  v-model.trim="loginForm.password"
                />
                <p class="mt-1 error-text" v-if="errors.password">
                  {{ errors.password[0] }}
                </p>
              </form>
            </div>
            <div
              class="checkbox d-flex align-items-center justify-content-between"
            >
              <div class="form-checkbox pt-0 mb-0">
                <input
                  type="checkbox"
                  class="custom-checkbox"
                  id="signin-remember"
                  name="signin-remember"
                />
                <label class="form-control-label" for="signin-remember"
                  >Remember Me</label
                >
              </div>
              <a href="#" class="lost-link">Lost your password?</a>
            </div>
            <div class="link-group">
              <a
                href="#"
                class="btn btn-dark btn-rounded mb-4"
                @click.prevent="login"
                >Login</a
              >
              <span class="d-inline-block text-body font-weight-semi-bold"
                >or Login With</span
              >
              <div class="social-links mb-4">
                <a href="#" class="social-link social-google fab fa-google"></a>
                <a
                  href="#"
                  class="social-link social-facebook fab fa-facebook-f"
                ></a>
                <a
                  href="#"
                  class="social-link social-twitter fab fa-twitter"
                ></a>
              </div>
            </div>
          </div>
        </div>
        <div class="card accordion">
          <div
            class="alert alert-light alert-primary alert-icon mb-4 card-header"
          >
            <i class="fas fa-exclamation-circle"></i>
            <span class="text-body">Have a coupon? </span>
            <a href="#alert-body2" class="text-primary"
              >Click here to enter your code</a
            >
          </div>
          <div class="alert-body mb-4 collapsed" id="alert-body2">
            <p>If you have a coupon code, please apply it below.</p>
            <div class="check-coupon-box d-flex">
              <input
                type="text"
                name="coupon_code"
                class="input-text form-control text-grey ls-m mr-4"
                id="coupon_code"
                value=""
                placeholder="Coupon code"
              />
              <button
                type="submit"
                class="btn btn-dark btn-rounded btn-outline"
              >
                Apply Coupon
              </button>
            </div>
          </div>
        </div>
        <form action="#" class="form">
          <div class="row">
            <div class="col-lg-7 mb-6 mb-lg-0 pr-lg-4">
              <h3 class="title title-simple text-left text-uppercase">
                Billing Details
              </h3>
              <div class="row">
                <div class="col-xs-6">
                  <label>First Name *</label>
                  <input
                    type="text"
                    class="form-control"
                    name="first-name"
                    required=""
                    :class="{
                      empty:
                        findFieldinFeedback('first_name') &&
                        orderForm.first_name === '',
                    }"
                    v-model="orderForm.first_name"
                  />
                  <p class="error-text" v-if="errors.first_name">
                    {{ errors.first_name[0] }}
                  </p>
                </div>
                <div class="col-xs-6">
                  <label>Last Name *</label>
                  <input
                    type="text"
                    class="form-control"
                    name="last-name"
                    required=""
                    :class="{
                      empty:
                        findFieldinFeedback('last_name') &&
                        orderForm.last_name === '',
                    }"
                    v-model="orderForm.last_name"
                  />
                  <p class="error-text" v-if="errors.last_name">
                    {{ errors.last_name[0] }}
                  </p>
                </div>
              </div>
              <label>Email Address *</label>
              <input
                type="text"
                class="form-control"
                name="email-address"
                required=""
                :class="{
                  empty:
                    findFieldinFeedback('email') &&
                    (orderForm.email === '' ||
                      !orderForm.email.includes('@') ||
                      !orderForm.email.includes('.')),
                }"
                v-model="orderForm.email"
              />
              <p class="error-text" v-if="errors.email">
                {{ errors.email[0] }}
              </p>
              <label>Phone *</label>
              <input
                type="text"
                class="form-control"
                name="phone"
                required=""
                :class="{
                  empty:
                    findFieldinFeedback('phone') && orderForm.phone === '',
                }"
                v-model="orderForm.phone"
              />
              <p class="error-text" v-if="errors.phone">
                {{ errors.phone[0] }}
              </p>
              <div class="row">
                <div class="col-xs-6">
                  <label>Password *</label>
                  <input
                    type="text"
                    class="form-control"
                    name="password"
                    required=""
                    :class="{
                      empty:
                        (findFieldinFeedback('password') &&
                          orderForm.password === '') ||
                        orderForm.password !==
                          orderForm.password_confirmation,
                    }"
                    v-model="orderForm.password"
                  />
                  <p class="error-text" v-if="errors.password">
                    {{ errors.password[0] }}
                  </p>
                </div>
                <div class="col-xs-6">
                  <label>Password Confirmation *</label>
                  <input
                    type="text"
                    class="form-control"
                    name="password"
                    required=""
                    :class="{
                      empty:
                        findFieldinFeedback('password_confirmation') &&
                        orderForm.password_confirmation === '',
                    }"
                    v-model="orderForm.password_confirmation"
                  />
                  <p class="error-text" v-if="errors.password_confirmation">
                    {{ errors.password_confirmation[0] }}
                  </p>
                </div>
              </div>
              <label>Search Address</label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter an address to search"
                required=""
              />
              <div class="row">
                <div class="col-xs-6">
                  <label>Street *</label>
                  <input
                    type="text"
                    class="form-control"
                    name="city"
                    required=""
                    :class="{
                      empty:
                        findFieldinFeedback('street_number') &&
                        orderForm.address.street_number === '',
                    }"
                    v-model="orderForm.address.street_number"
                  />
                </div>
                <div class="col-xs-6">
                  <label>Zip Code</label>
                  <input
                    type="text"
                    class="form-control"
                    name="state"
                    required=""
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-xs-6">
                  <label>Locality</label>
                  <input
                    type="text"
                    class="form-control"
                    name="city"
                    required=""
                  />
                </div>
                <div class="col-xs-6">
                  <label>City *</label>
                  <input
                    type="text"
                    class="form-control"
                    name="state"
                    required=""
                    :class="{
                      empty:
                        findFieldinFeedback('locality') &&
                        orderForm.address.locality === '',
                    }"
                    v-model="orderForm.address.locality"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-xs-6">
                  <label>State</label>
                  <input
                    type="text"
                    class="form-control"
                    name="city"
                    required=""
                  />
                </div>
                <div class="col-xs-6">
                  <label>Country</label>
                  <input
                    type="text"
                    class="form-control"
                    name="state"
                    required=""
                    :class="{
                      empty:
                        findFieldinFeedback('country') &&
                        orderForm.address.country === '',
                    }"
                    v-model="orderForm.address.country"
                  />
                </div>
              </div>
              <div class="form-checkbox mb-0">
                <input
                  type="checkbox"
                  class="custom-checkbox"
                  id="create-account"
                  name="create-account"
                  v-model="orderForm.createAccount"
                />
                <label class="form-control-label ls-s" for="create-account"
                  >Create an account?</label
                >
              </div>
              <div class="form-checkbox mb-6">
                <input
                  type="checkbox"
                  class="custom-checkbox"
                  id="different-address"
                  name="different-address"
                />
                <label class="form-control-label ls-s" for="different-address"
                  >Ship to a different address?</label
                >
              </div>
              <h2 class="title title-simple text-uppercase text-left">
                Additional Information
              </h2>
              <label>Order Notes (Optional)</label>
              <textarea
                class="form-control pb-2 pt-2 mb-0"
                cols="30"
                rows="5"
                placeholder="Notes about your order, e.g. special notes for delivery"
              ></textarea>
            </div>
            <aside class="col-lg-5 sticky-sidebar-wrapper">
              <div class="pin-wrapper" style="height: 873.156px">
                <div
                  class="sticky-sidebar mt-1"
                  data-sticky-options="{'bottom': 50}"
                  style="
                    border-bottom: 0px none rgb(102, 102, 102);
                    width: 480px;
                  "
                >
                  <div class="summary pt-5">
                    <h3 class="title title-simple text-left text-uppercase">
                      Your Order
                    </h3>
                    <table class="order-table">
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="product in cart" :key="product.id">
                          <td class="product-name">
                            {{ displayName(product.name) }}
                            <span class="product-quantity"
                              >×&nbsp;{{ product.amountInCart }}</span
                            >
                          </td>
                          <td class="product-total text-body">
                            ${{ getTotalPrice(product) }}
                          </td>
                        </tr>
                        <tr class="summary-subtotal">
                          <td>
                            <h4 class="summary-subtitle">Subtotal</h4>
                          </td>
                          <td class="summary-subtotal-price pb-0 pt-0">
                            $ {{ cartTotal.toFixed(2) }}
                          </td>
                        </tr>
                        <tr class="sumnary-shipping shipping-row-last">
                          <td colspan="2">
                            <h4 class="summary-subtitle">Calculate Shipping</h4>
                            <ul>
                              <li>
                                <div class="custom-radio">
                                  <input
                                    type="radio"
                                    id="flat_rate"
                                    name="shipping"
                                    class="custom-control-input"
                                    checked=""
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="flat_rate"
                                    >Flat rate</label
                                  >
                                </div>
                              </li>

                              <li>
                                <div class="custom-radio">
                                  <input
                                    type="radio"
                                    id="free-shipping"
                                    name="shipping"
                                    class="custom-control-input"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="free-shipping"
                                    >Free shipping</label
                                  >
                                </div>
                              </li>

                              <li>
                                <div class="custom-radio">
                                  <input
                                    type="radio"
                                    id="local_pickup"
                                    name="shipping"
                                    class="custom-control-input"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="local_pickup"
                                    >Local pickup</label
                                  >
                                </div>
                              </li>
                            </ul>
                          </td>
                        </tr>
                        <tr class="summary-total">
                          <td class="pb-0">
                            <h4 class="summary-subtitle">Total</h4>
                          </td>
                          <td class="pt-0 pb-0">
                            <p class="summary-total-price ls-s text-primary">
                              $ {{ cartTotal.toFixed(2) }}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="payment accordion radio-type">
                      <h4 class="summary-subtitle ls-m pb-3">
                        Payment Methods
                      </h4>
                      <div class="card">
                        <div class="card-header">
                          <a
                            href="#collapse1"
                            class="collapse text-body text-normal ls-m"
                            >Check payments
                          </a>
                        </div>
                        <div
                          id="collapse1"
                          class="expanded"
                          style="display: block"
                        >
                          <div class="card-body ls-m">
                            Please send a check to Store Name, Store Street,
                            Store Town, Store State / County, Store Postcode.
                          </div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <a
                            href="#collapse2"
                            class="expand text-body text-normal ls-m"
                            >Cash on delivery</a
                          >
                        </div>
                        <div id="collapse2" class="collapsed">
                          <div class="card-body ls-m">
                            Pay with cash upon delivery.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-checkbox mt-4 mb-5">
                      <input
                        type="checkbox"
                        class="custom-checkbox"
                        id="terms-condition"
                        name="terms-condition"
                      />
                      <label class="form-control-label" for="terms-condition">
                        I have read and agree to the website
                        <a href="#">terms and conditions </a>*
                      </label>
                    </div>
                    <button
                      type="submit"
                      @click.prevent="placeOrder"
                      class="btn btn-dark btn-rounded btn-order"
                    >
                      Place Order
                    </button>
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </form>
      </div>
    </div>
  </main>
</template>

<script>
import displayNameMixin from "../mixins/displayNameMixin";
import setFeedback from "../mixins/setFeedback";
export default {
  mixins: [displayNameMixin, setFeedback],
  data() {
    return {
      feedback: [],
      errors: [],
      loginForm: {
        email: "",
        password: "",
      },
      orderForm: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        password: "",
        password_confirmation: "",
        address: {
          street_number: "",
          sublocality_level_1: "",
          locality: "",
          administrative_area_level_1: "",
          country: "",
          postal_code: "",
          is_billing: true,
          is_default: true,
        },
        createAccount: false
      },
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
    cart() {
      return this.$store.getters["orders/cart"];
    },
    cartTotal() {
      return this.$store.getters["orders/cartTotal"];
    },
  },
  methods: {
    getTotalPrice(product) {
      return product.amountInCart * product.price;
    },
    async login() {
      this.errors = [];
      console.log("Login");
      if (!this.validateLogin()) {
        return;
      }
      console.log("Submit");
      try {
        const response = await this.$store.dispatch(
          "auth/login",
          this.loginForm
        );
        if (response.status === 200) {
          this.$store.dispatch("auth/setAuth");
        }
      } catch (err) {
        if (!!err.response && err.response.status === 422) {
          this.errors = err.response.data.errors;
        } else {
          console.log("Error");
          console.log(err);
        }
      }
    },
    async placeOrder() {
      if (!this.isLoggedIn && !this.validateRegister()) {
        return;
      }

      console.log(this.orderForm);
      return;

      // try {
      //   const response = await this.$store.dispatch(
      //     "auth/register",
      //     this.orderForm
      //   );
      //   console.log(response.data);
      // } catch (err) {
      //   if (!!err.response && err.response.status === 422) {
      //     this.errors = err.response.data.errors;
      //   } else {
      //     console.log("Error");
      //     console.log(err);
      //   }
      // }
    },
    validateLogin() {
      this.feedback = [];
      if (
        this.loginForm.email === "" ||
        !this.loginForm.email.includes("@") ||
        !this.loginForm.email.includes(".")
      ) {
        this.setFeedback("error", "email");
      }
      if (this.loginForm.password === "") {
        this.setFeedback("error", "password");
      }
      if (this.feedback.length !== 0) {
        return false;
      }
      return true;
    },
    validateRegister() {
      this.feedback = [];
      this.errors = [];
      if (this.orderForm.first_name === "") {
        this.setFeedback("error", "first_name");
      }
      if (this.orderForm.last_name === "") {
        this.setFeedback("error", "last_name");
      }
      if (
        this.orderForm.email === "" ||
        !this.orderForm.email.includes("@") ||
        !this.orderForm.email.includes(".")
      ) {
        this.setFeedback("error", "email");
      }
      if (this.orderForm.phone === "") {
        this.setFeedback("error", "phone");
      }
      if (
        this.orderForm.password === "" ||
        this.orderForm.password !== this.orderForm.password_confirmation
      ) {
        this.setFeedback("error", "password");
      }
      if (this.orderForm.password_confirmation === "") {
        this.setFeedback("error", "password_confirmation");
      }
      if (this.orderForm.address.street_number === "") {
        this.setFeedback("error", "street_number");
      }
      if (this.orderForm.address.locality === "") {
        this.setFeedback("error", "locality");
      }
      if (this.orderForm.address.country === "") {
        this.setFeedback("error", "country");
      }
      if (this.feedback.length !== 0) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped>
.empty {
  border-color: red;
}
.error-text {
  color: red;
  font-size: 1rem;
}
</style>
