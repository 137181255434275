import axios from "axios";

export default {
  getPaginatedProducts(_, payload) {
    return axios({
      method: "POST",
      url: "/api/v1/spa/products/paginated",
      data: payload,
    });
  }
};
