import axios from "axios";

export default {
  async getCategories(context) {
    const response = await axios({ method: "GET", url: "/api/v1/spa/categories" });
    context.commit("setCategories", response.data.categories);
  },

  getCategory(_, payload) {
      let uri = `/api/v1/spa/categories/${payload}`;
      return axios({ method: "GET", url: uri });
  },

  getPaginatedProducts(_, payload) {
    return axios({
      method: "POST",
      url: "/api/v1/spa/categories/paginated",
      data: payload,
    });
  }
  // submitCategory(context, payload) {
  //     axios.defaults.headers.common["Authorization"] =
  //         "Bearer " + context.rootGetters.token;

  //     let submit_method = "POST";
  //     let uri = "/api/v1/categories";
  //     let submit_data = payload;
  //     return axios({ method: submit_method, url: uri, data: submit_data });
  // },


  // setCategories(context, payload) {
  //     context.commit("setCategories", payload);
  // },

  // updateCategory(context, payload) {
  //     axios.defaults.headers.common["Authorization"] =
  //         "Bearer " + context.rootGetters.token;

  //     let submit_method = "PUT";
  //     let uri = `/api/v1/categories/${payload.id}`;
  //     let submit_data = payload;
  //     return axios({ method: submit_method, url: uri, data: submit_data });
  // },

  // updateCategoryStatus(context, payload) {
  //     axios.defaults.headers.common["Authorization"] =
  //         "Bearer " + context.rootGetters.token;

  //     let submit_method = "PUT";
  //     let uri = `/api/v1/categories/status/${payload.id}`;
  //     let submit_data = payload;
  //     return axios({ method: submit_method, url: uri, data: submit_data });
  // },

  // deleteCategory(context, payload) {
  //     axios.defaults.headers.common["Authorization"] =
  //         "Bearer " + context.rootGetters.token;

  //     let submit_method = "DELETE";
  //     let uri = `/api/v1/categories/${payload}`;
  //     let submit_data = payload;
  //     return axios({ method: submit_method, url: uri, data: submit_data });
  // },
};
