export default {
  addToCart(state, payload) {
    state.cart.push(payload);
  },
  removeFromCart(state, payload) {
    const product_index = state.cart.findIndex((product) => {
      return product.id === payload.id;
    });
    state.cart.splice(product_index, 1);
  },
  changeAmountInCart(state, payload) {
    const product_index = state.cart.findIndex((product) => {
      return product.id === payload.productId;
    });
    state.cart[product_index].amountInCart = payload.newQuantity;
  }
};
