<template>
  <nav class="toolbox toolbox-pagination">
    <p class="show-info">
      Showing {{ pagination.perPage }} of {{ pagination.total }} Products
    </p>
    <ul class="pagination">
      <li
        class="page-item"
        :class="{
          disabled: pagination.prevPageUrl === null,
        }"
      >
        <a
          class="page-link page-link-prev"
          aria-label="Previous"
          tabindex="-1"
          aria-disabled="true"
          @click="$emit('prev')"
        >
          <i class="d-icon-arrow-left"></i>Prev
        </a>
      </li>
      <li
        class="page-item"
        v-if="pagination.currentPage === pagination.lastPage && pagination.currentPage - 2 > 0"
      >
        <a class="page-link" @click="goToPage(pagination.currentPage - 2)">{{
          pagination.currentPage - 2
        }}</a>
      </li>
      <li
        class="page-item"
        v-if="pagination.currentPage !== 1"
        aria-current="page"
      >
        <a class="page-link" @click="goToPage(pagination.currentPage - 1)">{{
          pagination.currentPage - 1
        }}</a>
      </li>
      <li class="page-item active">
        <a class="page-link">{{ pagination.currentPage }}</a>
      </li>
      <li
        v-if="pagination.nextPageUrl"
        class="page-item"
      >
        <a class="page-link" @click="goToPage(pagination.currentPage + 1)">{{
          pagination.currentPage + 1
        }}</a>
      </li>
      <li class="page-item" v-if="pagination.currentPage === 1 && pagination.currentPage + 2 <= pagination.lastPage ">
        <a class="page-link" @click="goToPage(pagination.currentPage + 2)">{{
          pagination.currentPage + 2
        }}</a>
      </li>
      <li
        class="page-item"
        :class="{
          disabled: pagination.nextPageUrl === null,
        }"
      >
        <a
          class="page-link page-link-next"
          aria-label="Next"
          @click="$emit('next')"
        >
          Next<i class="d-icon-arrow-right"></i>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: ["pagination"],
  emits: ["next", "prev", "goTo"],
  methods: {
    goToPage(page) {
      const path = `${this.pagination.path}?page=${page}`;
      this.$emit("goTo", path);
    },
  },
};
</script>

<style scoped>
a {
  cursor: pointer;
}
</style>