export default {
  cart(state) {
    return state.cart;
  },
  cartTotal(state) {
    return state.cart.reduce((acc, curr) => {
      let price = parseFloat(curr.amountInCart) * parseFloat(curr.price);
      return acc + price;
    }, 0);
  },
};
