// import axios from "axios";

export default {
  addToCart(context, payload) {
    payload.amountInCart = 1; // Add one pc to cart by default
    context.commit("addToCart", payload);
    localStorage.setItem("cart", JSON.stringify(context.getters.cart));
  },
  removeFromCart(context, payload) {
    context.commit("removeFromCart", payload);
    localStorage.setItem("cart", JSON.stringify(context.getters.cart));
  },
  changeAmountInCart(context, payload) {
    context.commit("changeAmountInCart", payload);
    localStorage.setItem("cart", JSON.stringify(context.getters.cart));
  },
};
