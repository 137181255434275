import { createApp } from 'vue'
import App from './components/App.vue'
import router from "./router.js";
import store from "./store/main.js";
import axios from 'axios';

import Auth from "./components/authentication/Auth.vue";
import MainNav from "./components/partials/MainNav.vue";
import Pagination from "./components/presentation/Pagination.vue";

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.withCredentials = true;

const app = createApp(App);

app.use(router);
app.use(store);

app.component('auth', Auth);
app.component('main-nav', MainNav);
app.component('pagination', Pagination);

app.mount('#app')

