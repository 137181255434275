import axios from "axios";

export default {
  getCookie() {
    return axios({ method: "GET", url: "/sanctum/csrf-cookie" });
  },
  async register(context, payload) {
    await context.dispatch("getCookie");
    return axios({
      method: "POST",
      url: "/api/v1/spa/register",
      data: payload,
    });
  },
  async login(context, payload) {
    await context.dispatch("getCookie");
    return axios({
      method: "POST",
      url: "/api/v1/spa/login",
      data: payload,
    });
  },

  setAuth(context) {
    localStorage.setItem("auth", true);
    context.commit("setAuth");
  },

  async logout(context, payload) {
    localStorage.removeItem("auth");
    context.commit("removeAuth");
    await context.dispatch("getCookie");
    return axios({
      method: "POST",
      url: "/api/v1/spa/logout",
      data: payload,
    });
  },

  async getUser(_, payload) {
    return axios({
      method: "GET",
      url: "/api/user",
      data: payload,
    });
  },
};
