export default {
    categories(state) {
        return state.categories;
    },
    // categoriesAndAll(state) {
    //     let categories = state.categories;
    //     categories.unshift({
    //         id: null,
    //         full_name: "All"
    //     });
    //     return categories;
    // },
};
