<template>
  <div class="modal-div">
    <div @click="$emit('close')" class="modal-div"></div>
    <dialog class="dialog" open>
      <div class="login-popup">
        <div class="form-box">
          <div class="tab tab-nav-simple tab-nav-boxed form-tab">
            <ul
              class="
                nav nav-tabs nav-fill
                align-items-center
                border-no
                justify-content-center
                mb-5
              "
              role="tablist"
            >
              <li class="nav-item">
                <a
                  @click="setUp('login')"
                  class="nav-link active border-no lh-1 ls-normal"
                  href="#signin"
                  >Login</a
                >
              </li>
              <li class="delimiter">or</li>
              <li class="nav-item">
                <a
                  class="nav-link border-no lh-1 ls-normal"
                  href="#register"
                  @click="setUp('register')"
                  >Register</a
                >
              </li>
            </ul>
            <div class="tab-content">
              <div class="tab-pane active" id="signin">
                <form @submit.prevent="submitForm">
                  <div class="form-group mb-3">
                    <input
                      v-model="form.email"
                      type="email"
                      class="form-control"
                      placeholder="Your Email Address *"
                      required
                    />
                    <p
                      class="mt-1 error-text"
                      v-if="errors.email && this.type === 'login'"
                    >
                      {{ errors.email[0] }}
                    </p>
                  </div>
                  <div class="form-group">
                    <input
                      v-model="form.password"
                      type="password"
                      class="form-control"
                      placeholder="Password *"
                      required
                    />
                    <p
                      class="mt-1 error-text"
                      v-if="errors.password && this.type === 'login'"
                    >
                      {{ errors.password[0] }}
                    </p>
                  </div>
                  <div class="form-footer">
                    <div class="form-checkbox">
                      <input
                        type="checkbox"
                        class="custom-checkbox"
                        id="signin-remember"
                        name="signin-remember"
                      />
                      <label class="form-control-label" for="signin-remember"
                        >Remember me</label
                      >
                    </div>
                    <a href="#" class="lost-link">Lost your password?</a>
                  </div>
                  <button
                    class="btn btn-dark btn-block btn-rounded"
                    type="submit"
                  >
                    Login
                  </button>
                </form>
                <div class="form-choice text-center">
                  <label class="ls-m">or Login With</label>
                  <div class="social-links">
                    <a
                      href="#"
                      class="social-link social-google fab fa-google border-no"
                    ></a>
                    <a
                      href="#"
                      class="
                        social-link social-facebook
                        fab
                        fa-facebook-f
                        border-no
                      "
                    ></a>
                    <a
                      href="#"
                      class="
                        social-link social-twitter
                        fab
                        fa-twitter
                        border-no
                      "
                    ></a>
                  </div>
                </div>
              </div>
              <div class="tab-pane" id="register">
                <form @submit.prevent="submitForm('register')">
                  <div class="form-group">
                    <input
                      v-model="form.first_name"
                      type="text"
                      class="form-control"
                      placeholder="Your First Name *"
                      required
                    />
                    <p class="mt-1 error-text" v-if="errors.first_name">
                      {{ errors.first_name[0] }}
                    </p>
                  </div>
                  <div class="form-group">
                    <input
                      v-model="form.last_name"
                      type="text"
                      class="form-control"
                      placeholder="Your Last Name *"
                      required
                    />
                    <p class="mt-1 error-text" v-if="errors.last_name">
                      {{ errors.last_name[0] }}
                    </p>
                  </div>
                  <div class="form-group mb-3">
                    <input
                      v-model="form.email"
                      type="email"
                      class="form-control"
                      placeholder="Your Email Address *"
                      required
                    />
                    <p
                      class="mt-1 error-text"
                      v-if="errors.email && this.type === 'register'"
                    >
                      {{ errors.email[0] }}
                    </p>
                  </div>
                  <div class="form-group mb-3">
                    <input
                      v-model="form.phone"
                      type="text"
                      class="form-control"
                      placeholder="Your Phone *"
                      required
                    />
                    <p class="mt-1 error-text" v-if="errors.phone">
                      {{ errors.phone[0] }}
                    </p>
                  </div>
                  <div class="form-group">
                    <input
                      v-model="form.password"
                      type="password"
                      class="form-control"
                      placeholder="Password *"
                      required
                    />
                    <p
                      class="mt-1 error-text"
                      v-if="errors.password && this.type === 'register'"
                    >
                      {{ errors.password[0] }}
                    </p>
                  </div>
                  <div class="form-group">
                    <input
                      v-model="form.password_confirmation"
                      type="password"
                      class="form-control"
                      placeholder="Password Confirmation *"
                      required
                    />
                    <p
                      class="mt-1 error-text"
                      v-if="errors.password_confirmation"
                    >
                      {{ errors.password_confirmation[0] }}
                    </p>
                  </div>
                  <div class="form-footer">
                    <div class="form-checkbox">
                      <input
                        type="checkbox"
                        class="custom-checkbox"
                        id="register-agree"
                        name="register-agree"
                        required
                      />
                      <label class="form-control-label" for="register-agree"
                        >I agree to the privacy policy</label
                      >
                    </div>
                  </div>
                  <button
                    class="btn btn-dark btn-block btn-rounded"
                    type="submit"
                  >
                    Register
                  </button>
                </form>
                <div class="form-choice text-center">
                  <label class="ls-m">or Register With</label>
                  <div class="social-links">
                    <a
                      href="#"
                      class="social-link social-google fab fa-google border-no"
                    ></a>
                    <a
                      href="#"
                      class="
                        social-link social-facebook
                        fab
                        fa-facebook-f
                        border-no
                      "
                    ></a>
                    <a
                      href="#"
                      class="
                        social-link social-twitter
                        fab
                        fa-twitter
                        border-no
                      "
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </dialog>
  </div>
</template>

<script>
export default {
  emits: ["close"],
  data() {
    return {
      form: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        password: "",
        password_confirmation: "",
      },
      errors: [],
      type: "login",
    };
  },

  methods: {
    async submitForm() {
      this.errors = [];
      try {
        if (this.type === "register") {
          const response = await this.$store.dispatch(
            "auth/register",
            this.form
          );
          if (response.status === 201) {
            this.clearForm();
          }
        } else {
          const response = await this.$store.dispatch("auth/login", this.form);
          if (response.status === 200) {
            this.$store.dispatch("auth/setAuth");
            this.$emit("close");
          }
        }
      } catch (err) {
        if (!!err.response && err.response.status === 422) {
          this.errors = err.response.data.errors;
        } else {
          console.log("Error");
          console.log(err);
        }
      }
    },
    setUp(type) {
      this.type = type;
      this.errors = [];
      this.clearForm();
    },
    clearForm() {
      this.form.first_name = "";
      this.form.last_name = "";
      this.form.email = "";
      this.form.password = "";
      this.form.password_confirmation = "";
    },
  },
};
</script>

<style scoped>
.modal-div {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 10000;
}

.dialog {
  position: fixed;
  top: 20vh;
  left: 10%;
  width: 80%;
  z-index: 10001;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  overflow: hidden;
}

@media (min-width: 768px) {
  .dialog {
    left: calc(50% - 25rem);
    width: 50rem;
  }
}

.error-text {
  color: red;
  font-size: 1rem;
}
</style>