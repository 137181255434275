import { createRouter, createWebHistory } from "vue-router";

import Home from "./components/home/Home.vue";
import Category from "./components/categories/Category.vue";
import Cart from "./components/orders/Cart.vue";
import Checkout from "./components/orders/Checkout.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: Home,
    },
    {
      path: "/category/:slug?",
      component: Category,
      props: true
    },
    {
      path: "/cart",
      component: Cart
    },
    {
      path: "/checkout",
      component: Checkout
    }
  ],
});

export default router;
