import { createStore } from "vuex";

import authModule from "./authentication/index.js";
import categoriesModule from "./categories/index.js";
import productsModule from "./products/index.js";
import ordersModule from "./orders/index.js";

const store = createStore({
  modules: {
    auth: authModule,
    categories: categoriesModule,
    products: productsModule,
    orders: ordersModule,
  },
});

export default store;
