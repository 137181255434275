<template>
  <main class="main">
    <nav class="breadcrumb-nav">
      <div class="container">
        <ul class="breadcrumb">
          <li>
            <a href="demo1.html"><i class="d-icon-home"></i></a>
          </li>
          <li>Shop</li>
        </ul>
      </div>
    </nav>
    <div class="page-content pb-10 mb-3">
      <div class="container">
        <div class="row gutter-lg main-content-wrap">
          <aside
            class="
              col-lg-3
              sidebar sidebar-fixed
              shop-sidebar
              sticky-sidebar-wrapper
            "
          >
            <div class="sidebar-overlay"></div>
            <a href="#" class="sidebar-toggle"
              ><i class="fas fa-chevron-right"></i
            ></a>
            <a class="sidebar-close" href="#"><i class="d-icon-times"></i></a>
            <div class="sidebar-content">
              <div class="pin-wrapper" style="height: 1704.06px">
                <div
                  class="sticky-sidebar"
                  style="
                    border-bottom: 0px none rgb(102, 102, 102);
                    width: 221.25px;
                  "
                >
                  <div class="widget widget-collapsible">
                    <h3 class="widget-title">
                      {{ categoryName }}<span class="toggle-btn"></span>
                    </h3>
                    <ul
                      v-if="childCategories.length > 0"
                      class="widget-body filter-items search-ul"
                    >
                      <li
                        v-for="category in childCategories"
                        :key="category.id"
                        class="with-ul show"
                      >
                        <router-link :to="`/category/${category.slug}`">
                          {{ category.name
                          }}<i
                            v-if="
                              category.children && category.children.length > 0
                            "
                            class="fas fa-chevron-down"
                          ></i>
                        </router-link>
                        <ul
                          v-if="
                            category.children && category.children.length > 0
                          "
                        >
                          <li
                            v-for="child in category.children"
                            :key="child.id"
                          >
                            <router-link :to="`/category/${child.slug}`">
                              {{ child.name }}
                            </router-link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div class="widget widget-collapsible">
                    <h3 class="widget-title">
                      Filter by Price<span class="toggle-btn"></span>
                    </h3>
                    <div class="widget-body mt-3">
                      <form action="#">
                        <div
                          class="
                            filter-price-slider
                            noUi-target noUi-ltr noUi-horizontal
                          "
                        >
                          <div class="noUi-base">
                            <div class="noUi-connects">
                              <div
                                class="noUi-connect"
                                style="
                                  transform: translate(0%, 0px) scale(1, 1);
                                "
                              ></div>
                            </div>
                            <div
                              class="noUi-origin"
                              style="
                                transform: translate(-100%, 0px);
                                z-index: 5;
                              "
                            >
                              <div
                                class="noUi-handle noUi-handle-lower"
                                data-handle="0"
                                tabindex="0"
                                role="slider"
                                aria-orientation="horizontal"
                                aria-valuemin="0.0"
                                aria-valuemax="100.0"
                                aria-valuenow="0.0"
                                aria-valuetext="18.00"
                              ></div>
                            </div>
                            <div
                              class="noUi-origin"
                              style="transform: translate(0%, 0px); z-index: 4"
                            >
                              <div
                                class="noUi-handle noUi-handle-upper"
                                data-handle="1"
                                tabindex="0"
                                role="slider"
                                aria-orientation="horizontal"
                                aria-valuemin="0.0"
                                aria-valuemax="100.0"
                                aria-valuenow="100.0"
                                aria-valuetext="35.00"
                              ></div>
                            </div>
                          </div>
                        </div>

                        <div class="filter-actions">
                          <div class="filter-price-text mb-4">
                            Price:
                            <span class="filter-price-range">$18 - $35</span>
                          </div>
                          <button
                            type="submit"
                            class="btn btn-dark btn-filter btn-rounded"
                          >
                            Filter
                          </button>
                        </div>
                      </form>
                      <!-- End Filter Price Form -->
                    </div>
                  </div>
                  <div class="widget widget-collapsible">
                    <h3 class="widget-title">
                      Size<span class="toggle-btn"></span>
                    </h3>
                    <ul class="widget-body filter-items">
                      <li><a href="#">Extra Large</a></li>
                      <li><a href="#">Large</a></li>
                      <li><a href="#">Medium</a></li>
                      <li><a href="#">Small</a></li>
                    </ul>
                  </div>
                  <div class="widget widget-collapsible">
                    <h3 class="widget-title">
                      Color<span class="toggle-btn"></span>
                    </h3>
                    <ul class="widget-body filter-items">
                      <li><a href="#">Black</a></li>
                      <li><a href="#">Blue</a></li>
                      <li><a href="#">Green</a></li>
                      <li><a href="#">White</a></li>
                    </ul>
                  </div>
                  <div class="widget widget-collapsible">
                    <h3 class="widget-title">
                      Brands<span class="toggle-btn"></span>
                    </h3>
                    <ul class="widget-body filter-items">
                      <li><a href="#">Cinderella</a></li>
                      <li><a href="#">Comedy</a></li>
                      <li><a href="#">Rightcheck</a></li>
                      <li><a href="#">SkillStar</a></li>
                      <li><a href="#">SLS</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </aside>
          <div class="col-lg-9 main-content">
            <div
              class="shop-banner-default banner mb-1"
              style="
                background-image: url('images/shop/banner.jpg');
                background-color: #4e6582;
              "
            >
              <div class="banner-content">
                <h4
                  class="
                    banner-subtitle
                    font-weight-bold
                    ls-normal
                    text-uppercase text-white
                  "
                >
                  Riode Shop
                </h4>
                <h1 class="banner-title font-weight-bold text-white">
                  Banner with Sidebar
                </h1>
                <a
                  href="#"
                  class="
                    btn btn-white btn-outline btn-icon-right btn-rounded
                    text-normal
                  "
                  >Discover now<i class="d-icon-arrow-right"></i
                ></a>
              </div>
            </div>
            <nav class="toolbox sticky-toolbox sticky-content fix-top">
              <div class="toolbox-left">
                <div class="toolbox-item toolbox-sort select-box text-dark">
                  <label>Sort By :</label>
                  <select v-model="sortBy" name="orderby" class="form-control">
                    <option value="price-low">Price: Low to High</option>
                    <option value="price-high">Price: High to Low</option>
                  </select>
                </div>
              </div>
              <div class="toolbox-right">
                <div class="toolbox-item toolbox-show select-box text-dark">
                  <label>Show :</label>
                  <select
                    name="count"
                    class="form-control"
                    v-model="tableData.length"
                    @change="getPaginatedProducts()"
                  >
                    <option value="3">3</option>
                    <option value="6">6</option>
                    <option value="12">12</option>
                  </select>
                </div>
                <div class="toolbox-item toolbox-layout">
                  <a
                    href="shop-list.html"
                    class="d-icon-mode-list btn-layout"
                  ></a>
                  <a
                    href="shop.html"
                    class="d-icon-mode-grid btn-layout active"
                  ></a>
                </div>
              </div>
            </nav>
            <div class="row cols-2 cols-sm-3 product-wrapper">
              <div
                class="product-wrap"
                v-for="product in products"
                :key="product.id"
              >
                <div class="product">
                  <figure class="product-media">
                    <a href="product.html">
                      <img
                        style="width: 280px; height: 315px"
                        :src="getFirstImageLink(product)"
                        alt="product"
                        width="280"
                        height="315"
                      />
                    </a>
                    <div class="product-label-group">
                      <label class="product-label label-new">new</label>
                      <label class="product-label label-sale">12% OFF</label>
                    </div>
                    <div class="product-action-vertical">
                      <a
                        v-if="!checkIfInCart(product.id)"
                        @click.prevent="addBtnHandler(product)"
                        class="btn-product-icon btn-cart"
                        data-toggle="modal"
                        data-target="#addCartModal"
                        title="Add to cart"
                        ><i class="d-icon-bag"></i
                      ></a>
                      <a
                        v-if="checkIfInCart(product.id)"
                        @click.prevent="addBtnHandler(product)"
                        class="btn-product-icon btn-cart"
                        title="Remove from cart"
                        ><i class="d-icon-bag"></i
                      ></a>
                      <a
                        href="#"
                        class="btn-product-icon btn-wishlist"
                        title="Add to wishlist"
                        ><i class="d-icon-heart"></i
                      ></a>
                    </div>
                    <div class="product-action">
                      <a
                        href="#"
                        class="btn-product btn-quickview"
                        title="Quick View"
                        >Quick View</a
                      >
                    </div>
                  </figure>
                  <div class="product-details">
                    <div class="product-cat">
                      <a href="shop-grid-3col.html">{{
                        getProductCategoryName(product)
                      }}</a>
                    </div>
                    <h3 class="product-name">
                      <a href="product.html">{{ product.name }}</a>
                    </h3>
                    <div class="product-price">
                      <ins class="new-price">${{ product.price }}</ins
                      ><del class="old-price">${{ product.price }}</del>
                    </div>
                    <div class="ratings-container">
                      <div class="ratings-full">
                        <span class="ratings" style="width: 60%"></span>
                        <span class="tooltiptext tooltip-top"></span>
                      </div>
                      <a href="product.html" class="rating-reviews"
                        >( 16 reviews )</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <pagination
              :pagination="pagination"
              @prev="getPaginatedProducts(pagination.prevPageUrl)"
              @next="getPaginatedProducts(pagination.nextPageUrl)"
              @goTo="goToPage"
            ></pagination>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import axios from "axios";

export default {
  props: {
    slug: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      products: [],
      sortBy: "price-low",
      tableData: {
        sortBy: "price",
        length: 6,
        dir: "asc",
        slug: "",
      },
      pagination: {
        path: "",
        perPage: "",
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
      },
    };
  },
  created() {
    if (this.slug) {
      this.tableData.slug = this.slug;
    }
    this.getPaginatedProducts();
  },
  watch: {
    slug: function () {
      this.tableData.slug = this.slug;
      this.tableData.sortBy = "price";
      this.tableData.dir = "asc";
      this.getPaginatedProducts();
    },
    sortBy: function (newValue) {
      if (newValue === "price-low") {
        this.tableData.sortBy = "price";
        this.tableData.dir = "asc";
      } else if (newValue === "price-high") {
        this.tableData.sortBy = "price";
        this.tableData.dir = "desc";
      }
      this.getPaginatedProducts();
    },
  },
  computed: {
    categoryName() {
      if (Object.keys(this.category).length > 0) {
        return this.category.name;
      }
      return "All Categories";
    },
    category() {
      if (!this.slug) {
        return {};
      }
      return this.categories.find((category) => {
        return category.slug === this.slug;
      });
    },
    categories() {
      return this.$store.getters["categories/categories"];
    },
    childCategories() {
      if (!this.slug) {
        return this.topLevelCategories;
      }
      if (this.slug && this.category.children.length > 0) {
        return this.category.children;
      }
      return [];
    },
    topLevelCategories() {
      return this.categories.filter((category) => {
        return category.parent === null;
      });
    },
    cart() {
      return this.$store.getters["orders/cart"];
    },
  },
  methods: {
    goToPage(url) {
      this.getPaginatedProducts(url);
    },
    configPagination(data) {
      this.pagination.path = data.path;
      this.pagination.lastPage = data.last_page;
      this.pagination.perPage = data.per_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },
    async getPaginatedProducts(url = "/api/v1/spa/products/paginated") {
      const response = await axios({
        method: "POST",
        url: url,
        data: this.tableData,
      });

      if (response.status === 200) {
        let data = response.data;
        this.products = data.products.data;
        this.configPagination(data.products);
      }
    },
    getFirstImageLink(product) {
      if (product.photos && product.photos.length > 0) {
        return product.photos[0].file_path;
      }
      return "/images/shop/1.jpg";
    },

    getProductCategoryName(product) {
      if (Object.keys(this.category).length > 0) {
        return this.category.name;
      }
      if (product.categories && product.categories.length > 0) {
        return product.categories[0].name;
      }
      return "";
    },

    addBtnHandler(product) {
      if (this.checkIfInCart(product.id)) {
        this.removeFromCart(product);
      } else {
        this.addToCart(product);
      }
    },

    addToCart(product) {
      this.$store.dispatch("orders/addToCart", product);
    },
    removeFromCart(product) {
      this.$store.dispatch("orders/removeFromCart", product);
    },
    checkIfInCart(product_id) {
      const category = this.cart.find((product) => {
        return product.id === product_id;
      });
      return category ? true : false;
    },
  },
};
</script>